import React, { useState } from 'react';
import {Button, Input } from 'antd';

const SendEmailInput = (props) => {
  const [mail, setMail] = useState('');
  const [validation, setValidation] = useState(false);
  const submit = () => {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regex.test(mail);

    if (valid) {
        props.SendMail(mail);
      setMail('');
    } else {
      setValidation(true);
      setTimeout(() => {
        setValidation(false);
      }, 2000);
    }
  };

  return (
    <div style={{marginRight:10,display:'flex',width:'37%'}} >
    <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
     <Input placeholder='Enter email address...' style={{height:'38px'}} onChange={(e) => setMail(e.target.value)} value={mail}/>
     <div
style={{
color: 'red',
fontWeight: 400,
textAlign:'start',
marginLeft:3,
width: '100%',
letterSpacing: 0.5,
}}
>
{validation && 'Not a valid email'}
</div>
</div>
     <Button style={{background:'#38B6FF',color:'white'}} onClick={()=>submit()}>Send</Button>
 </div>
  );
};

export default SendEmailInput;
